<template>
  <div class="main">
    <a-form
      id="formLogin"
      class="user-layout-login"
      ref="formLogin"
      :form="form"
      @submit="handleSubmit"
    >
      <a-tabs
        :activeKey="customActiveKey"
        :tabBarStyle="{ textAlign: 'center', borderBottom: 'unset' }"
        @change="tabChange"
      >
        <a-tab-pane key="account_password" tab="账号密码登录">
          <a-alert
            v-if="isLoginError"
            type="error"
            showIcon
            style="margin-bottom: 24px"
            message="账户或密码错误"
          />
          <a-form-item>
            <a-input
              size="large"
              type="text"
              placeholder="请输入账户"
              v-decorator="[
                'name',
                {
                  rules: [
                    { required: true, message: '请输入帐户名' },
                    { validator: handleUsernameOrEmail },
                  ],
                  validateTrigger: 'change',
                },
              ]"
            >
              <a-icon
                slot="prefix"
                type="user"
                :style="{ color: 'rgba(0,0,0,.25)' }"
              />
            </a-input>
          </a-form-item>

          <a-form-item>
            <a-input-password
              size="large"
              placeholder="请输入密码"
              v-decorator="[
                'password',
                {
                  rules: [{ required: true, message: '请输入密码' }],
                  validateTrigger: 'blur',
                },
              ]"
            >
              <a-icon
                slot="prefix"
                type="lock"
                :style="{ color: 'rgba(0,0,0,.25)' }"
              />
            </a-input-password>
          </a-form-item>
          <a-form-item style="margin-top: 24px">
            <a-button
              size="large"
              type="primary"
              htmlType="submit"
              class="login-button"
              :loading="state.loginBtn"
              :disabled="state.loginBtn"
              >确定</a-button
            >
          </a-form-item>
        </a-tab-pane>
        <a-tab-pane key="qywx" tab="微信扫码登录">
          <div id="qywx_login" v-if="loggingIn"></div>
          <div v-else-if="!userInfo.bind">
            <a-alert
              message="您的企业微信账号尚未绑定分享起源账户，请在下方输入分享起源的账号和密码进行绑定"
              banner
              style="margin-bottom: 24px"
            />
            <a-form-item>
              <a-input
                size="large"
                type="text"
                placeholder="请输入账户"
                v-decorator="[
                  'name',
                  {
                    rules: [
                      { required: true, message: '请输入帐户名' },
                      { validator: handleUsernameOrEmail },
                    ],
                    validateTrigger: 'change',
                  },
                ]"
              >
                <a-icon
                  slot="prefix"
                  type="user"
                  :style="{ color: 'rgba(0,0,0,.25)' }"
                />
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-input-password
                autocomplete="off"
                size="large"
                placeholder="请输入密码"
                v-decorator="[
                  'password',
                  {
                    rules: [{ required: true, message: '请输入密码' }],
                    validateTrigger: 'blur',
                  },
                ]"
              >
                <a-icon
                  slot="prefix"
                  type="lock"
                  :style="{ color: 'rgba(0,0,0,.25)' }"
                />
              </a-input-password>
            </a-form-item>
            <a-form-item style="margin-top: 24px">
              <a-button
                size="large"
                type="primary"
                @click="handleBinding"
                class="login-button"
                :loading="state.loginBtn"
                :disabled="state.loginBtn"
                >确认绑定并登录</a-button
              >
            </a-form-item>
          </div>
          <div v-else class="load">
            <a-spin size="large" />
          </div>
        </a-tab-pane>
      </a-tabs>

      <!-- <a-form-item>
        <a-checkbox v-decorator="['rememberMe', { valuePropName: 'checked' }]">自动登录</a-checkbox>
        <router-link
          :to="{ name: 'recover', params: { user: 'aaa'} }"
          class="forge-password"
          style="float: right;"
        >忘记密码</router-link>
      </a-form-item> -->
    </a-form>
  </div>
</template>

<script>
import md5 from "md5";
import { mapActions } from "vuex";
import { timeFix } from "@/utils/util";
import { uuid } from "@/utils/uuid";
import { verifyQYWXLogin, bindBeforeLogin } from "@/api/user";
import store from "@/store";
import storage from "store";
import WwLogin from "@/utils/wwLogin-1.2.5";

export default {
  data() {
    return {
      customActiveKey: "account_password",
      loginBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      isLoginError: false,
      form: this.$form.createForm(this),
      state: {
        time: 60,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
      },
      loggingIn: true,
      loginState: sessionStorage.getItem("state"),
      code: this.$route.query.code || "",
      callbackState: this.$route.query.state || "",
      userInfo: {},
    };
  },
  created() {
    if (this.callbackState !== "") {
      this.customActiveKey = "qywx";
      //如果能拿到callbackState,说明是已经扫码登录完成了
      if (this.callbackState == this.loginState) {
        verifyQYWXLogin({ code: this.code, platform: "ADMIN" }).then((res) => {
          this.loggingIn = false;
          this.userInfo = res.data;
          if (this.userInfo.bind) {
            //已绑定直接进入系统
            this.userInfo.token = "4291d7da9005377ec9aec4a71ea837f";
            storage.set(
              "ACCESS_TOKEN",
              this.userInfo.token,
              7 * 24 * 60 * 60 * 1000
            );
            store.commit("SET_TOKEN", this.userInfo.token);
            this.loginSuccess();
          }
        });
      } else {
        //拿到的state与设定的state不同，校验不通过
        this.$notification["error"]({
          message: "错误",
          description: "登录校验不通过",
          duration: 4,
        });
      }
    }
  },
  methods: {
    ...mapActions(["Login", "Logout"]),
    // handler
    handleUsernameOrEmail(rule, value, callback) {
      const { state } = this;
      const regex =
        /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/;
      if (regex.test(value)) {
        state.loginType = 0;
      } else {
        state.loginType = 1;
      }
      callback();
    },
    handleSubmit(e) {
      e.preventDefault();
      const {
        form: { validateFields },
        state,
        Login,
      } = this;

      state.loginBtn = true;

      const validateFieldsKey = ["name", "password"];

      validateFields(validateFieldsKey, { force: true }, (err, values) => {
        if (!err) {
          const loginParams = { ...values };
          loginParams.password = md5(values.password).toUpperCase();
          Login(loginParams)
            .then((res) => this.loginSuccess(res))
            .catch((err) => this.requestFailed(err))
            .finally(() => {
              state.loginBtn = false;
            });
        } else {
          setTimeout(() => {
            state.loginBtn = false;
          }, 600);
        }
      });
    },
    loginSuccess() {
      window.location.href = "/";
      // this.$router.push({ path: "/" });
      // 延迟 1 秒显示欢迎信息
      setTimeout(() => {
        this.$notification.success({
          message: "欢迎",
          description: `${timeFix()}，欢迎回来`,
        });
      }, 1000);
      this.isLoginError = false;
    },
    requestFailed(err) {
      this.isLoginError = true;
      this.$notification["error"]({
        message: "错误",
        description:
          ((err.response || {}).data || {}).message ||
          "请求出现错误，请稍后再试",
        duration: 4,
      });
    },
    tabChange(activeKey) {
      this.customActiveKey = activeKey;
      if (this.customActiveKey === "qywx" && this.loggingIn) {
        this.loginState = uuid().toLowerCase();
        sessionStorage.setItem("state", this.loginState);
        this.$nextTick(() => {
          new WwLogin({
            id: "qywx_login",
            appid: process.env.VUE_APP_QYWX_CORP_ID,
            agentid: process.env.VUE_APP_QYWX_AGENT_ID,
            redirect_uri: encodeURI(
              window.location.protocol + process.env.VUE_APP_DOMAIN_NAME + "/user/login"
            ),
            state: this.loginState,
            href: "",
            lang: "zh",
          });
        });
      }
    },
    handleBinding() {
      const {
        form: { validateFields },
        state,
      } = this;
      const validateFieldsKey = ["name", "password"];
      validateFields(validateFieldsKey, { force: true }, (err, values) => {
        if (!err) {
          const loginParams = { ...values };
          loginParams.password = md5(values.password).toUpperCase();
          loginParams.platform = "ORIGIN";
          loginParams.wxUserId = this.userInfo.wechatUserId;
          bindBeforeLogin(loginParams).then((res) => {
            this.userInfo = res.data;
            this.userInfo.token = "4291d7da9005377ec9aec4a71ea837f";
            storage.set(
              "ACCESS_TOKEN",
              this.userInfo.token,
              7 * 24 * 60 * 60 * 1000
            );
            store.commit("SET_TOKEN", this.userInfo.token);
            this.loginSuccess();
          });
        } else {
          setTimeout(() => {
            state.loginBtn = false;
          }, 600);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.user-layout-login {
  label {
    font-size: 14px;
  }

  .forge-password {
    font-size: 14px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .item-icon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #1890ff;
      }
    }

    .register {
      float: right;
    }
  }
}
#qywx_login {
  margin-left: 36px;
}
.load {
  margin-top: 100px;
  text-align: center;
}
</style>
