var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('a-form',{ref:"formLogin",staticClass:"user-layout-login",attrs:{"id":"formLogin","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-tabs',{attrs:{"activeKey":_vm.customActiveKey,"tabBarStyle":{ textAlign: 'center', borderBottom: 'unset' }},on:{"change":_vm.tabChange}},[_c('a-tab-pane',{key:"account_password",attrs:{"tab":"账号密码登录"}},[(_vm.isLoginError)?_c('a-alert',{staticStyle:{"margin-bottom":"24px"},attrs:{"type":"error","showIcon":"","message":"账户或密码错误"}}):_vm._e(),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'name',
              {
                rules: [
                  { required: true, message: '请输入帐户名' },
                  { validator: _vm.handleUsernameOrEmail } ],
                validateTrigger: 'change',
              } ]),expression:"[\n              'name',\n              {\n                rules: [\n                  { required: true, message: '请输入帐户名' },\n                  { validator: handleUsernameOrEmail },\n                ],\n                validateTrigger: 'change',\n              },\n            ]"}],attrs:{"size":"large","type":"text","placeholder":"请输入账户"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'password',
              {
                rules: [{ required: true, message: '请输入密码' }],
                validateTrigger: 'blur',
              } ]),expression:"[\n              'password',\n              {\n                rules: [{ required: true, message: '请输入密码' }],\n                validateTrigger: 'blur',\n              },\n            ]"}],attrs:{"size":"large","placeholder":"请输入密码"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',{staticStyle:{"margin-top":"24px"}},[_c('a-button',{staticClass:"login-button",attrs:{"size":"large","type":"primary","htmlType":"submit","loading":_vm.state.loginBtn,"disabled":_vm.state.loginBtn}},[_vm._v("确定")])],1)],1),_c('a-tab-pane',{key:"qywx",attrs:{"tab":"微信扫码登录"}},[(_vm.loggingIn)?_c('div',{attrs:{"id":"qywx_login"}}):(!_vm.userInfo.bind)?_c('div',[_c('a-alert',{staticStyle:{"margin-bottom":"24px"},attrs:{"message":"您的企业微信账号尚未绑定分享起源账户，请在下方输入分享起源的账号和密码进行绑定","banner":""}}),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'name',
                {
                  rules: [
                    { required: true, message: '请输入帐户名' },
                    { validator: _vm.handleUsernameOrEmail } ],
                  validateTrigger: 'change',
                } ]),expression:"[\n                'name',\n                {\n                  rules: [\n                    { required: true, message: '请输入帐户名' },\n                    { validator: handleUsernameOrEmail },\n                  ],\n                  validateTrigger: 'change',\n                },\n              ]"}],attrs:{"size":"large","type":"text","placeholder":"请输入账户"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'password',
                {
                  rules: [{ required: true, message: '请输入密码' }],
                  validateTrigger: 'blur',
                } ]),expression:"[\n                'password',\n                {\n                  rules: [{ required: true, message: '请输入密码' }],\n                  validateTrigger: 'blur',\n                },\n              ]"}],attrs:{"autocomplete":"off","size":"large","placeholder":"请输入密码"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',{staticStyle:{"margin-top":"24px"}},[_c('a-button',{staticClass:"login-button",attrs:{"size":"large","type":"primary","loading":_vm.state.loginBtn,"disabled":_vm.state.loginBtn},on:{"click":_vm.handleBinding}},[_vm._v("确认绑定并登录")])],1)],1):_c('div',{staticClass:"load"},[_c('a-spin',{attrs:{"size":"large"}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }